import styled, { css } from 'styled-components';
import React from 'react';
import TableSearchList from '../molecule/TableSearchList';
import { Button, Header, Tag } from '@veneer/core';
import { TableFilter } from 'src/components/molecule';
import { TableExportList } from 'src/components/organism';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { COLUMN_LABEL } from 'src/utils/constants';

type TTableHeaderProps = {
  loading?: boolean;
  disable?: boolean;
  showExportAll?: boolean;
  selectedItems?: any;
  columnConfig?;
  columnContents?;
  handleFilterOption?;
  handleFilterClearAll?;
  selectedFilter?;
  selectedFilterCount?: number;
};

const TableHeader = (props: TTableHeaderProps) => {
  const { isWex, isFromApps } = useShellRootContext();
  const ecpCustomStyle = { backgroundColor: '#E0ECF1', color: '#014667' };
  const wxCustomStyle = {
    background: 'var(--Primary-Background, rgba(76, 82, 229, 0.10))',
    color: 'var(--Primary-Active, #2d2f86)',

    display: 'flex',
    padding: '4px var(--size-adjustment-34, 12px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--size-adjustment-24, 8px)',
  };

  const getTagList = () => {
    const parsedArray = JSON.parse(props.selectedFilter);
    return parsedArray.flatMap(([key, values]) => values.map((value: string) => `${key}:${value}`));
  };
  const localizeKeyValue = (keyValueString: string): string => {
    const [key, value] = keyValueString.split(':');
    return `${t(COLUMN_LABEL[key])}: ${value}`;
  };

  return (
    <Wrapper loading={props.loading}>
      <Header
        position="relative"
        data-testid="table-header"
        customStyle={{ border: 'none', background: 'transparent' }}
        leadingArea={
          <LeadingArea>
            <TableSearchList disabled={props.disable} />
            {!isFromApps && (
              <TableFilter
                handleFilterOption={props.handleFilterOption}
                selectedFilter={props.selectedFilter}
                selectedFilterCount={props.selectedFilterCount}
                disabled={props.loading || props.disable}
              />
            )}
          </LeadingArea>
        }
        trailingArea={
          props.showExportAll && (
            <TableExportList
              disabled={props.loading || props.disable}
              columnConfig={props.columnConfig}
              columnContents={props.columnContents}
            />
          )
        }
      />
      {!isFromApps && props.selectedFilterCount > 0 && (
        <TagContainer>
          {getTagList().map((tag) => (
            <Tag
              label={localizeKeyValue(tag)}
              key={tag}
              isTint
              clearButton
              onClose={() => props.handleFilterOption({ target: { name: tag } })}
              customStyle={isWex ? wxCustomStyle : ecpCustomStyle}
            />
          ))}
          {props.selectedFilterCount == 2 && (
            <Button appearance="ghost" onClick={props.handleFilterClearAll}>
              Clear all
            </Button>
          )}
        </TagContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-right: 6px;
  }
  ${(props) => {
    if (props.loading) {
      return css`
        margin-right: 30px;
      `;
    }
  }}
`;

const LeadingArea = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-adjustment-34, 12px);
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 10px;

  > div {
    border-radius: calc(24px / 2);

    span {
      font-family: 'Forma DJR UI';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
  }
`;

export default TableHeader;
