import { Button } from '@veneer/core';
import React, { useState } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t } from 'src/utils/commonMethods';
import styled from 'styled-components';
import DirectCloudOnboardingModal from './DirectCloudOnboardingModal';

const TableAddDeviceButton = () => {
  const {
    setShowAddDeviceModal,
    setAddDeviceModalTitle,
    setAddDeviceFooterButtonName,
    featureFlags,
  } = useShellRootContext();
  const [showDCOModal, setShowDCOModal] = useState(false);
  return (
    <ButtonWrapper>
      <Button
        data-testid={'table-add-device-add-button'}
        onClick={() => {
          if (featureFlags.enableDirectCloudOnboard) {
            setShowDCOModal(true);
          } else {
            setAddDeviceFooterButtonName(t('table.add_device_modal.done'));
            setAddDeviceModalTitle(t('table.add_device_modal.add_device'));
            setShowAddDeviceModal(true);
          }
        }}
        appearance={'secondary'}
      >
        {t('table.add_device_modal.add')}
      </Button>
      <DirectCloudOnboardingModal showDCOModal={showDCOModal} setShowDCOModal={setShowDCOModal} />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  margin-right: 12px;
`;

export default TableAddDeviceButton;
