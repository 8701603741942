import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeDirectCloudOnboarding = (props: any) => {
  const { stack, shell, localization, mode, demoModeOptions, demoEnabled } = useShellRootContext();
  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <MfeLoader
          disableContainerPadding
          {...props}
          {...{ component, type, localization, mode, demoModeOptions, demoEnabled }}
        />
      );
    },
    [shell, stack, props],
  );

  const renderSubMfe = useMemo(() => {
    console.log('SubMfeDirectCloudOnboarding renderSubMfe');
    return (
      <div className="sub-mfe-direct-colud-onboarding">
        <SubMfe
          type="ECPDirectCloudOnboarding"
          component="@jarvis/react-wex-direct-cloud-onboarding"
        />
      </div>
    );
  }, [props.showModal]);

  return <>{renderSubMfe}</>;
};

SubMfeDirectCloudOnboarding.defaultProps = {
  shell: {},
  stack: null,
};

SubMfeDirectCloudOnboarding.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeDirectCloudOnboarding;
