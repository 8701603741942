import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState, useEffect, useMemo } from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, Modal, ThemeProvider } from '@veneer/core';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { GroupTextBox, CreateGroupTree } from 'src/components/molecule';

type TCreateGroupModalProps = {
  showCreateGroupModal: boolean;
  setShowCreateGroupModal: (e) => void;
};

const CreateGroupModal = (props: TCreateGroupModalProps) => {
  const { refreshPageWithoutApi } = useRefreshHook();
  const { devicesGroupState, devicesRnPmState } = useStoreState();
  const { allDevicesGroupUuid, mainGroupData: groups } = devicesGroupState;
  const { createCollection } = collectionAPI();
  const [groupName, setGroupName] = useState(() => {
    const initialState = setInitialGroupName();
    return initialState;
  });
  const [isFetching, setIsFetching] = useState(false);
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const [groupNameSatisfied, setGroupNameSatisfied] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([allDevicesGroupUuid]);
  const { useToast, isWex } = useShellRootContext();

  function setInitialGroupName() {
    const newgroup = t('group.new_group');
    let newGroupNameIndex = 1;

    const groupNameList = groups?.map((group) => group.name);
    const newlyCreatedGroupName = sessionStorage.getItem('newlyCreatedGroupName');
    if (newlyCreatedGroupName) {
      groupNameList?.push(newlyCreatedGroupName);
    }

    // If 'New Group' does not exist
    if (groupNameList?.find((groupName) => groupName === newgroup) === undefined) {
      return newgroup;
    } else {
      // If 'New Group' is already exist, 'New Group (%d)' will be set.
      while (
        groupNameList?.find((groupName) => groupName === `${newgroup} (${newGroupNameIndex})`) !==
        undefined
      ) {
        newGroupNameIndex++;
      }
      return `${newgroup} (${newGroupNameIndex})`;
    }
  }

  useEffect(() => {
    const selectedGroupSatisfied = selectedGroup.length !== 0;

    if (selectedGroupSatisfied && groupNameSatisfied) {
      setEnableCreateButton(true);
    } else {
      setEnableCreateButton(false);
    }
  }, [selectedGroup, groupNameSatisfied, groupName]);

  const handleCreateGroupModal = async () => {
    const { error } = await createCollection(groupName, []);

    if (error) {
      useToast.addToast({
        id: 'createGroupModalFail',
        type: 'negative',
        text: t('group.create_group_fail_msg', { groupName: groupName }),
      });
    } else {
      sessionStorage.setItem('newlyCreatedGroupName', groupName);
      useToast.addToast({
        id: 'createGroupModalSuccess',
        type: 'positive',
        text: t('group.create_group_success_msg', { groupName: groupName }),
      });
    }
    setSelectedGroup([]);
  };

  const renderGroupTextBox = () => {
    const description = t('group.create_description');

    return (
      <ThemeProvider density={'high'} shape={'round'}>
        <GroupTextBox
          {...{
            groups,
            groupName,
            description,
            setGroupName,
            setGroupNameSatisfied,
          }}
        />
      </ThemeProvider>
    );
  };

  return (
    <Modal
      data-testid="create-group-modal"
      show={props.showCreateGroupModal}
      title=""
      maxWidth="612px"
      alignFooter={isWex ? undefined : 'end'}
      footer={
        <Footer>
          <Button
            data-testid="create-new-group-modal-action-button"
            onClick={() => {
              setIsFetching(true);

              if (selectedGroup) {
                handleCreateGroupModal().finally(() => {
                  setIsFetching(false);
                  props.setShowCreateGroupModal(false);
                  refreshPageWithoutApi();
                });
              }
            }}
            appearance="primary"
            small={true}
            disabled={!enableCreateButton}
            loading={isFetching}
          >
            {t('group.create')}
          </Button>

          <Button
            data-testid="create-new-group-modal-cancel-button"
            onClick={() => {
              props.setShowCreateGroupModal(false);
              setSelectedGroup([]);
            }}
            small={true}
            appearance="secondary"
            disabled={isFetching}
          >
            {t('group.cancel')}
          </Button>
        </Footer>
      }
    >
      <Title data-testid="create-new-group-modal-title">{t('group.create')}</Title>

      {renderGroupTextBox()}
    </Modal>
  );
};

const Footer = styled.div.attrs(() => {
  return { className: 'vn-button-group--responsive' };
})`
  display: flex;
  width: 100%;
  button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

const Title = styled.div`
  padding-bottom: 20px !important;
  width: 448px;
  font-family: var(--fontFamilyRegular);
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight6};
  vertical-align: middle !important;
`;

export default CreateGroupModal;
