import { store } from 'src/utils/constants';

const initialState = {
  // Common
  allDevicesGroupUuid: '',
  ungroupedGroupUuid: '',

  // Main Group
  mainGroupData: [],
  mainGroupSelectedId: '',
  mainGroupSelectedName: 'All',
  mainGroupRefresh: false,

  // Move Group Modal
  moveGroupModalData: [],
  moveGroupModalSelectedId: '',
  moveGroupModalSelectedName: '',

  // Responsive Group Modal
  flexShowGroupModal: false,

  // Wex
  showSideBar: true,
};

const devicesGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     *  Common
     */
    case store.devicesGroup.ALL_DEVICES_GROUP_UUID:
      return {
        ...state,
        allDevicesGroupUuid: action.payload,
      };

    case store.devicesGroup.UNGROUPED_GROUP_UUID:
      return {
        ...state,
        ungroupedGroupUuid: action.payload,
      };

    /**
     *  Main Group
     */
    case store.devicesGroup.MAIN_GROUP_DATA:
      return {
        ...state,
        mainGroupData: action.payload,
      };
    case store.devicesGroup.MAIN_GROUP_SELECTED_ID:
      return {
        ...state,
        mainGroupSelectedId: action.payload,
      };
    case store.devicesGroup.MAIN_GROUP_SELECTED_NAME:
      return {
        ...state,
        mainGroupSelectedName: action.payload,
      };
    case store.devicesGroup.MAIN_GROUP_REFRESH:
      return {
        ...state,
        mainGroupRefresh: action.payload,
      };

    /**
     *  Move Group Modal
     */
    case store.devicesGroup.MOVE_GROUP_MODAL_DATA:
      return {
        ...state,
        moveGroupModalData: action.payload,
      };
    case store.devicesGroup.MOVE_GROUP_MODAL_SELECTED_ID:
      return {
        ...state,
        moveGroupModalSelectedId: action.payload,
      };
    case store.devicesGroup.MOVE_GROUP_MODAL_SELECTED_NAME:
      return {
        ...state,
        moveGroupModalSelectedName: action.payload,
      };

    /**
     *  Responsive Group Modal
     */
    case store.devicesGroup.FLEX_SHOW_MAIN_GROUP_MODAL:
      return {
        ...state,
        flexShowGroupModal: action.payload,
      };

    /**
     *  WEX
     */
    case store.devicesGroup.SHOW_SIDE_BAR:
      return {
        ...state,
        showSideBar: action.payload,
      };

    /**
     *  Reset Device Group Reducer
     */
    case store.devicesGroup.RESET_DEVICE_GROUP_REDUCER:
      return {
        // Common
        allDevicesGroupUuid: '',
        ungroupedGroupUuid: '',

        // Main Group
        mainGroupData: [],
        mainGroupSelectedId: '',
        mainGroupSelectedName: 'All',
        mainGroupRefresh: true,

        // Edit Group
        editGroupData: [],
        editGroupSelectedIdList: [],
        editGroupSelectedName: '',
        editGroupTreeRefresh: true,

        // Create Group Modal
        createGroupModalData: [],
        createGroupModalSelectedId: '',

        // Move Group Modal
        moveGroupModalData: [],
        moveGroupModalSelectedId: '',
        moveGroupModalSelectedName: '',

        // Responsive Group Modal
        flexShowGroupModal: false,
      };

    default:
      return state;
  }
};

export default devicesGroupReducer;
