import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { getComplianceScore, getDateFromTableOrgData, t } from 'src/utils/commonMethods';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useEffect, useRef } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export const useSort = (columnConfig) => {
  const { tableHandler } = useTableHandler();
  const { devicesState } = useStoreState();
  const { isFromApps } = useShellRootContext();
  const { cfgApiRequestOptions, currentPage } = devicesState;
  const orderByAssessment = useRef(false);
  const orderByPolicyCompliance = useRef(false);
  const [getQueryParams] = useQueryParams();

  useEffect(() => {
    if (isFromApps) {
      return;
    }
    const queryParams: any = getQueryParams();
    if (queryParams && queryParams.showAssessment && queryParams.assessmentType == 'all') {
      orderByAssessment.current = true;
    }
    if (queryParams && queryParams.showCompliance && queryParams.complianceType == 'all') {
      orderByPolicyCompliance.current = true;
    }
  }, []);

  const getSliceEnd = (option, length) => {
    const totalPageCount = Math.floor(length / option.limit) + 1;
    const currentPage = option.offset;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * option.limit;
    } else {
      return length;
    }
  };

  const recalculatePage = (newSortedItems, currentPage) => {
    const newPageOption = {
      offset: currentPage - 1,
      limit: cfgApiRequestOptions.limit,
    };
    const lastIndex = getSliceEnd(newPageOption, newSortedItems.length);
    return newSortedItems.slice(newPageOption.offset * newPageOption.limit, lastIndex);
  };

  const sort = (id, type, items) => {
    const tableContents = tableHandler.getColumnContents(items);
    const entries = Object.entries(tableContents).map(([id, entry]) => {
      const val = columnConfig.cfgColumnsList().reduce((r, col) => {
        r[col.id] = entry[col.id];
        return r;
      }, {});
      val['id'] = id;
      return val;
    });

    const orderOfDate = ['addedDate', 'statusUpdated', 'lastSyncTime', 'policyDateRun'];
    const assessmentOrder = [
      t('table.high_risk'),
      t('table.medium_risk'),
      t('table.not_assessed'),
      t('table.low_risk'),
      t('table.passed'),
    ];

    const sortedOrder = entries.sort((a, b) => {
      //move null data to end
      if ((a[id] == '--' || a[id] == undefined) && (b[id] == '--' || b[id] == undefined)) return 0;
      if (a[id] == '--' || a[id] == undefined) return 1;
      if (b[id] == '--' || b[id] == undefined) return -1;

      if (id === 'assessment') {
        const aIndex = assessmentOrder.indexOf(a[id]) + 1;
        const bIndex = assessmentOrder.indexOf(b[id]) + 1;

        if (orderByAssessment.current) {
          orderByAssessment.current = false;
          return aIndex - bIndex;
        }

        return type === 'ascending' ? aIndex - bIndex : bIndex - aIndex;
      }

      if (orderOfDate.includes(id)) {
        const aDate = getDateFromTableOrgData(items, a['id'], id);
        const bDate = getDateFromTableOrgData(items, b['id'], id);

        const dateA = new Date(aDate);
        const dateB = new Date(bDate);
        return type === 'ascending'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      if (id === 'policyCompliance') {
        const aScore = getComplianceScore(items, a['id']);
        const bScore = getComplianceScore(items, b['id']);

        if (orderByPolicyCompliance.current) {
          orderByPolicyCompliance.current = false;
          return aScore - bScore;
        }

        return type === 'ascending' ? aScore - bScore : bScore - aScore;
      }

      return type === 'ascending' ? a[id].localeCompare(b[id]) : b[id].localeCompare(a[id]);
    });

    const orderMap: any = new Map(sortedOrder.map((item, index) => [item.id, index]));
    return items.sort((a, b) => orderMap.get(a.deviceId) - orderMap.get(b.deviceId));
  };

  const triggerSort = (totalDevices, sortById, sortByType) => {
    if (!sortById || !sortByType) {
      return { pagedItems: [], entireItems: [], count: 0, valid: false };
    }
    if (!totalDevices || (totalDevices && totalDevices.length == 0)) {
      return { pagedItems: [], entireItems: [], count: 0, valid: false };
    }

    const newSortedItems = sort(sortById, sortByType, totalDevices);
    const newPagedItems = recalculatePage(newSortedItems, currentPage);

    return {
      pagedItems: newPagedItems,
      entireItems: newSortedItems,
      count: newSortedItems.length,
      valid: true,
    };
  };

  return { triggerSort };
};
