import { getLokalisedGroupLabel } from 'src/utils/groupMethods';

export const isOutScopeLengthGroupName = (groupName) => {
  return groupName.length === 0 || 256 <= groupName.length;
};

export const isInvalidGroupName = (groupName) => {
  // const lengthValidation = 1 <= name.length && name.length <= 256;
  let asciiValidation = true;

  // limited special characters -> " / % \ [ ] : ; | = , + * ? < > & # { }
  const blackList = [
    34, 35, 37, 38, 42, 43, 44, 47, 58, 59, 60, 61, 62, 63, 91, 92, 93, 123, 124, 125,
  ];
  for (let i = 0; i < groupName.length; i++) {
    if (blackList.includes(groupName.charCodeAt(i))) {
      asciiValidation = false;
      break;
    }
  }
  // return !lengthValidation || !asciiValidation;
  return !asciiValidation;
};

export const isDuplicatedGroupName = (groupName, groups) => {
  const groupNames = groups?.map((group) => {
    return getLokalisedGroupLabel(group.name);
  });
  return groupNames?.includes(groupName);
};

export const isStartWithSpaceGroupName = (groupName) => {
  return groupName != null && groupName.length > 0 && groupName.at(0) == ' ';
};
