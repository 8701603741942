import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState, useEffect } from 'react';
import { t } from 'src/utils/commonMethods';
import { GroupTextBox } from 'src/components/molecule';
import { collectionAPI } from 'src/api/collection';
import { Button } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TEditGroupCreateProps = {
  groupData: any[];
  parentGroupList: any[];
  refreshEditGroupModalPage: () => void;
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
};

const EditGroupCreate = (props: TEditGroupCreateProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [groupName, setGroupName] = useState(() => {
    const initialState = setInitialGroupName();
    return initialState;
  });
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const [groupNameSatisfied, setGroupNameSatisfied] = useState(false);
  const { useToast } = useShellRootContext();
  const { createCollection } = collectionAPI();

  function setInitialGroupName() {
    const newgroup = t('group.new_group');
    let newGroupNameIndex = 1;

    const groupNameList = props.groupData?.map((group) => group.name);
    const newlyCreatedGroupName = sessionStorage.getItem('newlyCreatedGroupName');
    if (newlyCreatedGroupName) {
      groupNameList?.push(newlyCreatedGroupName);
    }

    // If 'New Group' does not exist
    if (groupNameList?.find((groupName) => groupName === newgroup) === undefined) {
      return newgroup;
    } else {
      // If 'New Group' is already exist, 'New Group (%d)' will be set.
      while (
        groupNameList?.find((groupName) => groupName === `${newgroup} (${newGroupNameIndex})`) !==
        undefined
      ) {
        newGroupNameIndex++;
      }
      return `${newgroup} (${newGroupNameIndex})`;
    }
  }

  useEffect(() => {
    if (groupNameSatisfied) {
      setEnableCreateButton(true);
    } else {
      setEnableCreateButton(false);
    }
  }, [groupNameSatisfied, groupName]);

  const handleCreate = async () => {
    const { error } = await createCollection(groupName, []);

    if (error) {
      useToast.addToast({
        id: 'createGroupFail',
        type: 'negative',
        text: t('group.create_group_fail_msg', { groupName: groupName }),
      });
    } else {
      sessionStorage.setItem('newlyCreatedGroupName', groupName);
      useToast.addToast({
        id: 'createGroupSuccess',
        type: 'positive',
        text: t('group.create_group_success_msg', { groupName: groupName }),
      });
    }

    setGroupNameSatisfied(false);
  };

  const renderGroupTextBox = () => {
    const description = t('group.edit_groups_create_description');

    return (
      <CustomGroupTextBox data-testid={'edit-group-create-custom-group-text'}>
        <GroupTextBox
          {...{
            groups: props.groupData,
            groupName,
            description,
            setGroupName,
            setGroupNameSatisfied,
          }}
        />
      </CustomGroupTextBox>
    );
  };

  return (
    <Wrapper>
      {renderGroupTextBox()}

      <SavePanel>
        <Button
          data-testid="edit-group-modal-create-tab-action-button"
          appearance="primary"
          small={true}
          onClick={() => {
            setIsFetching(true);
            props.setActionCount((prevCount: number) => {
              return prevCount + 1;
            });
            handleCreate().finally(() => {
              setIsFetching(false);
              props.refreshEditGroupModalPage();
            });
          }}
          disabled={!enableCreateButton}
          loading={isFetching}
        >
          {t('group.create')}
        </Button>
      </SavePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 24px 44px 24px;
`;

const CustomGroupTextBox = styled.div`
  .vn-input {
    width: 397px;
  }
`;

const Label = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  ::after {
    content: '*';
    margin-left: 5px;
    color: ${tokens.color.red5};
    font-weight: bold;
  }
`;

const SavePanel = styled.div`
  display: flex;
  justify-content: right;
  position: absolute;
  right: 30px;

  > button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

export default EditGroupCreate;
